import React, { Fragment, useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import ActionButtons from "../Elements/ActionButtons"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import FinancialInformation from "./PatientEnrollment/FinancialInformation"
import SecondaryContactPerson from "./PatientEnrollment/SecondaryContactPerson"
import PrescriptionInformation from "./PatientEnrollment/PrescriptionInformation"
import PatientPersonalInformation from "./PatientEnrollment/PatientPersonalInformation"
import WhereDidYouHearAboutUs from "./PatientEnrollment/WhereDidYouHearAboutUs"
import PatientEnrollmentFeedback from "./PatientEnrollment/Feedback"

import { isBrowser } from "../../services/general"
import { AppContext } from "../../context/AppContext"
import { handleSignOut } from "../Auth/services/signout"
import { useSession } from "../../services/hooks/useSession"
import { patientEnrollmentValidationSchema } from "./utils/formData"
import { hasSignedInUser, getSignedInUser } from "../Auth/services/user"

const PatientEnrollment = () => {
  const IS_AUTH_ENABLED =
    process.env.GATSBY_HAS_AUTH_ENABLED === "enabled" ? true : false
  const { state, dispatch } = useContext(AppContext)
  const { patientEnrollment } = state
  const {
    sessionFormValues,
    isFormValuesEmpty,
    initializeFormValues,
    handleFormChange,
    clearFormValues,
  } = useSession("patientEnrollment")

  useEffect(() => {
    initializeFormValues()
  }, [])

  const handleSubmit = async (values) => {
    clearFormValues()

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        patientEnrollment: { ...patientEnrollment, ...values },
      },
    })
    navigate("/enroll-patient/summary")
  }

  useEffect(() => {
    let hasDeactivatedRole =
      isBrowser() && IS_AUTH_ENABLED
        ? getSignedInUser()?.userData?.roles?.find(
            (role) =>
              role.subdomain === process.env.GATSBY_AWS_S3_BUCKET_NAME &&
              role.status === "deactivated"
          )
        : null

    let hasActiveRole =
      isBrowser() && IS_AUTH_ENABLED
        ? getSignedInUser()?.userData?.roles?.find(
            (role) =>
              role.subdomain === process.env.GATSBY_AWS_S3_BUCKET_NAME &&
              role.status === "active"
          )
        : null

    if (isBrowser() && IS_AUTH_ENABLED) {
      let user = hasSignedInUser()
      if (!user && !state?.auth?.hasBeenVerified) navigate("/verify-email") // User is not verified
      if (!!user && !!hasActiveRole) navigate("/") // It is an active account
      if (!!hasDeactivatedRole) {
        // User is deactivated
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Your account has been deactivated",
            isCard: true,
            headerClass: `has-text-primary has-background-light is-size-5`,
            content: (
              <div>
                <p>
                  Your sign-in credentials may have been deactivated due to
                  being inactive for too long. Please enroll to the program
                  again.
                </p>
                <div className="buttons is-centered mt-2">
                  <div
                    className="button is-primary is-medium is-outlined"
                    onClick={() => handleSignOut({ redirect: true })}
                  >
                    Sign Out
                  </div>
                  <div
                    className="button is-primary is-medium"
                    onClick={() =>
                      dispatch({
                        type: "HIDE_MODAL",
                      })
                    }
                  >
                    Continue
                  </div>
                </div>
              </div>
            ),
          },
        })
      }
    }
  }, [])

  return (
    <Fragment>
      <Formik
        initialValues={
          isFormValuesEmpty
            ? {
                ...patientEnrollment,
                ...state?.auth,
                emailAddress:
                  state?.auth?.email ||
                  getSignedInUser()?.email ||
                  patientEnrollment?.emailAddress,
              }
            : { ...sessionFormValues }
        }
        validationSchema={patientEnrollmentValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, setFieldValue, isValid, submitCount }) => (
          <Form
            key="patientEnrollmentForm"
            onChange={() => handleFormChange(values)}
          >
            <PatientPersonalInformation
              values={values}
              onChange={(event) => handleChange(event)}
              setFieldValue={setFieldValue}
            />
            <SecondaryContactPerson />
            <PrescriptionInformation
              values={values}
              setFieldValue={setFieldValue}
            />
            <FinancialInformation values={values} />
            <WhereDidYouHearAboutUs values={values} />
            <PatientEnrollmentFeedback values={values} />
            {!isValid && submitCount > 0 && (
              <InvalidFormMessage color="danger" name="missingRequiredFields" />
            )}
            <ActionButtons submit={{ label: "Next" }} />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default PatientEnrollment
