import React from "react"

import Section from "../../Elements/Section"
import FormSelect from "../../Elements/Form/FormSelect"
import FormInput from "../../Elements/Form/FormInput"

const PatientEnrollmentFeedback = ({ values }) => {
  // NOTE: FormSelect from generateFormField couldn't show value but was able to change the value in the form
  // e.g. can change the value to 10 but doesn't show value in the field itself
  const satisfactionScoreProps = {
    name: "satisfactionScore",
    type: "select",
    label:
      "On a scale of 1 to 10, how satisfied are you with the enrollment process?",
    options: [
      { label: "10", value: "10" },
      { label: "9", value: "9" },
      { label: "8", value: "8" },
      { label: "7", value: "7" },
      { label: "6", value: "6" },
      { label: "5", value: "5" },
      { label: "4", value: "4" },
      { label: "3", value: "3" },
      { label: "2", value: "2" },
      { label: "1", value: "1" },
    ],
    isRequired: true,
  }

  const npsFeedbackProps = {
    name: "whatImprovementsWouldYouSuggestPatientEnrollment",
    type: "text",
    label:
      "What improvements would you suggest for the CarePlus enrollment process?",
    placeholder: "Please enter your feedback here",
    isRequired: true,
  }

  return (
    <Section title="Feedback" id="feedback">
      <FormSelect
        {...satisfactionScoreProps}
        value={values.satisfactionScore}
      />
      <FormInput {...npsFeedbackProps} value={values[npsFeedbackProps.name]} />
    </Section>
  )
}

export default PatientEnrollmentFeedback
